import React, { useEffect } from "react";
import { LinearProgress, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const GradientLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "20px",
  borderRadius: "5px",
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  "& .MuiLinearProgress-bar": {
    borderRadius: "5px",
    background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
  },
}));

export default function ZenProgress({ zenProgress }) {
  useEffect(() => {
    localStorage.setItem("chillaxly-zen-progress", zenProgress.toString());
  }, [zenProgress]);

  return (
    <Box
      position="absolute"
      display="flex"
      top={12}
      width="75vw"
      left="50%"
      style={{ transform: "translateX(-50%)" }}
      alignItems="center"
      flexDirection="column"
      gap={1}
    >
      <Box width="100%">
        {/* <small
          style={{
            fontWeight: 800,
            position: "absolute",
            zIndex: 2,
            left: "50%",
            transform: "translate(-50%)",
          }}
        >
          {zenProgress}%
        </small> */}
        <GradientLinearProgress variant="determinate" value={zenProgress} />
      </Box>
    </Box>
  );
}
