export default function GoogleAd() {
  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      dataAdClient="ca-pub-4962559735985300"
      dataAdSlot="6406919880"
      dataAdFormat="auto"
      dataFullWidthResponsive="true"
    ></ins>
  );
}
