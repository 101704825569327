import { Box, IconButton } from "@mui/material";
import Brightness7Icon from "@mui/icons-material/Brightness7"; // Sun icon
import Brightness3Icon from "@mui/icons-material/Brightness3"; // Moon icon

export default function ThemeToggle({ handleThemeChange, darkMode, color }) {
  return (
    <Box position="fixed" top={2} left={2}>
      <IconButton onClick={handleThemeChange}>
        {darkMode ? (
          <Brightness7Icon style={{ color: color }} />
        ) : (
          <Brightness3Icon style={{ color: color }} />
        )}
      </IconButton>
    </Box>
  );
}
