import React, { createContext, useContext, useEffect, useState } from "react";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThemeToggle from "./components/ThemeToggle";
import Meditate from "./pages/Meditate";
import NotFound from "./pages/NotFound";
import Landing from "./pages/Landing";

const ThemeContext = createContext();

export const useThemeValue = () => useContext(ThemeContext);

const App = () => {
  const translations = {
    "en-US": {
      welcomeMessage: "Find peace and calm in the midst of the storm",
      meditateButton: "Meditate Now",
      languageName: "English",
      languageDetected: "Language Detected",
      stepGuide: "Follow these simple steps for a calming experience",
      thumbInstruction:
        "Place your thumbs on the thumbprints at the bottom of the screen",
      breathingGuide: "Follow the on-screen prompts: inhale, hold, and exhale",
      zenBarInfo:
        "Your zen bar at the top of the screen will fill up as you meditate, but diminish over time",
      cool: "Cool",
      beginMeditation: "Press thumbs down to begin meditation",
      inhale: "Inhale",
      holdIn: "Hold In",
      exhale: "Exhale",
      holdOut: "Hold Out",
      cancel: "Cancel",
      startPrompt: "Click here to begin",
      default: "Default",
      midnightSun: "Midnight Sun",
      sakura: "Sakura",
      forestRetreat: "Forest Retreat",
      solarFlare: "Solar Flare",
      moonlit: "Moonlit",
      oceanic: "Oceanic",
      desertDusk: "Desert Dusk",
      winterNight: "Winter Night",
      aurora: "Aurora",
      monochrome: "Monochrome",
      roseGold: "Rose Gold",
      balanceAndCalm: "Balance and Calm",
      stressRelief: "Stress Relief",
      anxietyReduction: "Anxiety Reduction",
      bloodOxygenationPlus: "Blood Oxygenation+",
    },
    "es-ES": {
      welcomeMessage: "Encuentra paz y calma en medio de la tormenta",
      meditateButton: "Meditar Ahora",
      languageName: "Español",
      languageDetected: "Idioma Detectado",
      stepGuide: "Sigue estos sencillos pasos para una experiencia relajante",
      thumbInstruction:
        "Coloca tus pulgares en las huellas dactilares en la parte inferior de la pantalla",
      breathingGuide:
        "Sigue las indicaciones en pantalla: inhala, aguanta, y exhala",
      zenBarInfo:
        "Tu barra zen en la parte superior de la pantalla se llenará mientras meditas, pero disminuirá con el tiempo",
      cool: "Genial",
      beginMeditation:
        "Presiona hacia abajo con los pulgares para comenzar la meditación",
      inhale: "Inhala",
      holdIn: "Aguanta",
      exhale: "Exhala",
      holdOut: "Sostén afuera",
      cancel: "Cancelar",
      startPrompt: "Haz clic aquí para empezar",
      default: "Predeterminado",
      midnightSun: "Sol de Medianoche",
      sakura: "Sakura",
      forestRetreat: "Retiro en el Bosque",
      solarFlare: "Llamarada Solar",
      moonlit: "Iluminado por la Luna",
      oceanic: "Oceánico",
      desertDusk: "Crepúsculo del Desierto",
      winterNight: "Noche de Invierno",
      aurora: "Aurora",
      monochrome: "Monocromo",
      roseGold: "Oro Rosa",
      balanceAndCalm: "Equilibrio y Calma",
      stressRelief: "Alivio del Estrés",
      anxietyReduction: "Reducción de la Ansiedad",
      bloodOxygenationPlus: "Oxigenación de Sangre+",
    },
    "zh-CN": {
      welcomeMessage: "在风暴中寻找平静和宁静",
      meditateButton: "现在冥想",
      languageName: "中文",
      languageDetected: "检测到的语言",
      stepGuide: "按照这些简单的步骤进行舒缓的体验",
      thumbInstruction: "将你的拇指放在屏幕底部的指纹上",
      breathingGuide: "按照屏幕上的提示：吸气，屏住呼吸，然后呼气",
      zenBarInfo:
        "当你冥想时，屏幕顶部的禅定条会填满，但会随着时间的推移而减少",
      cool: "酷",
      beginMeditation: "按下拇指开始冥想",
      inhale: "吸气",
      holdIn: "屏住呼吸",
      exhale: "呼气",
      holdOut: "继续屏住",
      cancel: "取消",
      startPrompt: "点击这里开始",
      default: "默认",
      midnightSun: "午夜阳光",
      sakura: "樱花",
      forestRetreat: "森林静修",
      solarFlare: "太阳耀斑",
      moonlit: "月光",
      oceanic: "海洋",
      desertDusk: "沙漠黄昏",
      winterNight: "冬夜",
      aurora: "极光",
      monochrome: "单色",
      roseGold: "玫瑰金",
      balanceAndCalm: "平衡与宁静",
      stressRelief: "减轻压力",
      anxietyReduction: "减少焦虑",
      bloodOxygenationPlus: "血液氧合+",
    },
  };
  const prefersDarkMode = () => {
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  };

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("chillaxly-dark-theme")
      ? localStorage.getItem("chillaxly-dark-theme") === "false"
      : prefersDarkMode()
  );

  const [selectedTheme, setSelectedTheme] = useState(
    localStorage.getItem("chillaxly-color-mode")
      ? localStorage.getItem("chillaxly-color-mode").toString()
      : "default"
  );

  const userLanguage = navigator.language || navigator.userLanguage;

  const detectLanguage = (lang) => {
    const baseLang = lang.split("-")[0];

    switch (baseLang) {
      case "en":
        return "en-US";
      case "es":
        return "es-ES";
      case "zh":
        return "zh-CN";
      default:
        return "en-US";
    }
  };

  const detectedLanguageCode = detectLanguage(userLanguage);

  const getMessage = (key) => {
    if (
      translations[detectedLanguageCode] &&
      translations[detectedLanguageCode][key]
    ) {
      return translations[detectedLanguageCode][key];
    }
    return translations["en-US"][key];
  };

  const themes = {
    default: {
      primary: "#009999",
      secondary: "#9900cc",
    },
    midnightSun: {
      primary: "#FF5F6D",
      secondary: "#FFC371",
    },
    sakura: {
      primary: "#FF88A2",
      secondary: "#785964",
    },
    forestRetreat: {
      primary: "#4CAF50",
      secondary: "#8BC34A",
    },
    solarFlare: {
      primary: "#FF3030",
      secondary: "#FF6347",
    },
    moonlit: {
      primary: "#607D8B",
      secondary: "#B0BEC5",
    },
    oceanic: {
      primary: "#0077B5",
      secondary: "#60A3D9",
    },
    desertDusk: {
      primary: "#DAA520",
      secondary: "#FFD700",
    },
    winterNight: {
      primary: "#191970",
      secondary: "#483D8B",
    },
    aurora: {
      primary: "#8EE4AF",
      secondary: "#5CDB95",
    },
    monochrome: {
      primary: "#2D2D2D",
      secondary: "#AAAAAA",
    },
    roseGold: {
      primary: "#B76E79",
      secondary: "#FFC0CB",
    },
  };

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: themes[selectedTheme].primary,
      },
      secondary: {
        main: themes[selectedTheme].secondary,
      },
    },
  });

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("chillaxly-dark-theme", darkMode.toString());
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (event) => {
      setDarkMode(event.matches);
      localStorage.setItem("chillaxly-dark-theme", event.matches.toString());
    };

    mediaQuery.addEventListener("change", handleChange);

    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  return (
    <ThemeContext.Provider value={setSelectedTheme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Box
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Routes>
              <Route
                exact
                path="/"
                element={<Landing getMessage={getMessage} />}
              />
              <Route
                exact
                path="/meditation"
                element={
                  <Meditate
                    themes={themes}
                    theme={theme}
                    setSelectedTheme={setSelectedTheme}
                    getMessage={getMessage}
                  />
                }
              />
              <Route exact path="*" element={<NotFound />} />
            </Routes>
            <ThemeToggle
              color={theme.palette.primary.main}
              handleThemeChange={handleThemeChange}
              darkMode={darkMode}
            />
          </Box>
        </Router>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default App;
