import React, { memo, useEffect, useState } from "react";

const Circle = memo(({ x, y, primary, accent }) => {
  const circleStyle = {
    position: "absolute",
    top: y,
    left: x,
    animation: "upAndDown 5s infinite alternate",
    borderRadius: "50%",
    opacity: 0.111,
    width: "100px",
    height: "100px",
    background: `linear-gradient(45deg, ${primary}, ${accent})`,
  };

  return <div style={circleStyle}></div>;
});

const Modern = ({ primary, accent }) => {
  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [shapes, setShapes] = useState([]);

  const generateShapes = () => {
    let tempShapes = [];
    while (tempShapes.length < 10) {
      const x = Math.random() * screenDimensions.width;
      const y = Math.random() * screenDimensions.height;
      const size = Math.floor(Math.random() * 40) + 100;

      let doesCollide = tempShapes.some((circle) => {
        let dx = x - circle.x;
        let dy = y - circle.y;
        let distance = Math.sqrt(dx * dx + dy * dy);
        return distance < size / 2 + circle.size / 2;
      });

      if (!doesCollide) {
        tempShapes.push({ x, y, size });
      }
    }
    return tempShapes.sort((a, b) => a.size - b.size);
  };

  useEffect(() => {
    setShapes(generateShapes());

    const handleResize = () => {
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenDimensions.width, screenDimensions.height]);

  const containerStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: `${screenDimensions.width}px`,
    height: `${screenDimensions.height}px`,
    zIndex: 2,
    pointerEvents: "none",
  };

  return (
    <div style={containerStyle}>
      <style>
        {`
          @keyframes upAndDown {
            0% { transform: translateY(0); }
            100% { transform: translateY(20px); }
          }
        `}
      </style>
      {shapes.map((circle, index) => (
        <Circle key={index} accent={accent} primary={primary} {...circle} />
      ))}
    </div>
  );
};

export default memo(Modern);
