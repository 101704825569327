import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { Settings as Gear } from "@mui/icons-material";

export default function Settings({
  themes,
  setSelectedTheme,
  color,
  getMessage,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box position="fixed" top={2} right={2}>
      <IconButton onClick={handleClick}>
        <Gear style={{ color: color }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {!!Object.entries(themes).length &&
          Object.entries(themes).map(([theme]) => (
            <MenuItem
              key={`menu-item-${theme}`}
              onClick={() => {
                setSelectedTheme(theme);
                localStorage.setItem("chillaxly-color-mode", theme);
                handleClose();
              }}
            >
              {getMessage(theme)}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
}
