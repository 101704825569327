const Logo = () => {
  const gradients = [
    "linear-gradient(45deg, #FFC700, #FF3D00)",
    "linear-gradient(45deg, #FF3D00, #DD0463)",
    "linear-gradient(45deg, #DD0463, #932C88)",
    "linear-gradient(45deg, #932C88, #3925E5)",
    "linear-gradient(45deg, #3925E5, #00A4FF)",
    "linear-gradient(45deg, #00A4FF, #00FFC8)",
  ];

  const text = "Chillaxly";

  return (
    <div className="logo">
      {text.split("").map((char, index) => (
        <span
          key={index}
          style={{
            background: gradients[index % gradients.length],
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {char}
        </span>
      ))}
    </div>
  );
};

export default Logo;
