import { Container, Box, Button, Typography, Stack } from "@mui/material";
import Logo from "../components/Logo";
import { Link } from "react-router-dom";
import Rain from "../components/Rain";
import GoogleAd from "../components/GoogleAd";

export default function Landing({ getMessage }) {
  return (
    <Container>
      <Rain />
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100vw"
        height="100vh"
        zIndex={-1}
      />
      <Box textAlign="center" paddingY={5}>
        <Typography variant="h2" marginBottom={0}>
          <Logo />
        </Typography>
        <Typography
          className="pixelify"
          letterSpacing={1}
          variant="subtitle1"
          marginBottom={2}
        >
          {getMessage("welcomeMessage")} ☂️
        </Typography>
        <Stack>
          <Link
            style={{
              textDecoration: "none",
              width: "max-content",
              margin: "0 auto .75rem auto",
            }}
            to={"/meditation"}
          >
            <Button variant="contained" color="primary">
              {getMessage("meditateButton")}
            </Button>
          </Link>
        </Stack>
        <Typography
          style={{
            fontSize: 10,
            letterSpacing: 1,
          }}
        >
          🌐 {getMessage("languageDetected")}: {getMessage("languageName")}
        </Typography>
      </Box>
      <GoogleAd />
    </Container>
  );
}
