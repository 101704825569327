import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import Fingerprint from "@mui/icons-material/Fingerprint";
import { useState, useEffect } from "react";
import meditationTypes from "../data/meditationTypes.json";
import ZenProgressBar from "../components/ZenProgressBar";
import { useGesture } from "react-use-gesture";
import { Close } from "@mui/icons-material";
import Settings from "../components/Settings";
import Modern from "../components/Modern";

export default function Meditate({
  themes,
  theme,
  setSelectedTheme,
  getMessage,
}) {
  const [thumbsPressed, setThumbsPressed] = useState({
    left: false,
    right: false,
  });
  const [notification, setNotification] = useState("");
  const [isMeditating, setIsMeditating] = useState(false);
  const [meditationStep, setMeditationStep] = useState(null);
  const [meditationType, setMeditationType] = useState(
    localStorage.getItem("chillaxly-meditation-type")
      ? JSON.parse(localStorage.getItem("chillaxly-meditation-type"))
      : meditationTypes[0]
  );
  const [intervalId, setIntervalId] = useState(null);
  const [zenProgress, setZenProgress] = useState(
    localStorage.getItem("chillaxly-zen-progress")
      ? parseInt(localStorage.getItem("chillaxly-zen-progress"))
      : 0
  );
  const [isReturningVisitor, setIsReturningVisitor] = useState(
    localStorage.getItem("chillaxly-returning-visitor") ? true : false
  );
  const [isDialogOpen, setIsDialogOpen] = useState(!isReturningVisitor);
  const [lastUpdated, setLastUpdated] = useState(Date.now());

  const DRAIN_RATE_PER_HOUR = 5;

  function isTouchDevice() {
    return "ontouchstart" in window || navigator.maxTouchPoints;
  }

  const bindLeftThumb = useGesture({
    onTouchStart: () => setThumbsPressed((prev) => ({ ...prev, left: true })),
    onTouchMove: () => setThumbsPressed((prev) => ({ ...prev, left: true })),
    onTouchEnd: () => setThumbsPressed((prev) => ({ ...prev, left: false })),
  });

  const bindRightThumb = useGesture({
    onTouchStart: () => setThumbsPressed((prev) => ({ ...prev, right: true })),
    onTouchMove: () => setThumbsPressed((prev) => ({ ...prev, right: true })),
    onTouchEnd: () => setThumbsPressed((prev) => ({ ...prev, right: false })),
  });

  const drainZen = () => {
    const now = Date.now();
    const hoursPassed = (now - lastUpdated) / (1000 * 60 * 60);

    const amountToDrain = hoursPassed * DRAIN_RATE_PER_HOUR;

    setZenProgress((prevProgress) => Math.max(0, prevProgress - amountToDrain));
    setLastUpdated(now);
  };

  const handleSetReturningVisitor = () => {
    localStorage.setItem("chillaxly-returning-visitor", true.toString());
    setIsReturningVisitor(true);
  };

  useEffect(() => {
    const interval = setInterval(drainZen, 1000 * 60 * 10);

    const updateVisitorStatus = (e) => {
      if (e.key === "chillaxly-returning-visitor") {
        setIsReturningVisitor(e.newValue === "true");
      }
    };

    window.addEventListener("storage", updateVisitorStatus);

    return () => {
      clearInterval(interval);
      window.removeEventListener("storage", updateVisitorStatus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDuration = (currentType, step) => {
    switch (step) {
      case 0:
        return currentType.inhaleTime;
      case 1:
        return currentType.holdInTime;
      case 2:
        return currentType.exhaleTime;
      case 3:
        return currentType.holdOutTime;
      default:
        return 0;
    }
  };

  const generateMeditationMessage = (meditation) => {
    let messageParts = [];

    if (meditation.inhaleTime) {
      messageParts.push(`${meditation.inhaleTime / 1000}s in`);
    }

    if (meditation.holdInTime) {
      messageParts.push(`${meditation.holdInTime / 1000}s hold`);
    }

    if (meditation.exhaleTime) {
      messageParts.push(`${meditation.exhaleTime / 1000}s out`);
    }

    if (meditation.holdOutTime) {
      messageParts.push(`${meditation.holdOutTime / 1000}s hold`);
    }

    return messageParts.join(", ");
  };

  const deriveSteps = () => {
    return [
      getMessage("inhale"),
      getMessage("holdIn"),
      getMessage("exhale"),
      getMessage("holdOut"),
    ];
  };

  const cycleSteps = deriveSteps();

  const getTransitionStyle = () => {
    const currentType = meditationTypes.find(
      (type) => type.name === meditationType.name
    );

    let transformValue = "scale(1)";
    let transitionDuration = "1s";

    if (!currentType)
      return {
        transform: transformValue,
        transition: `transform ${transitionDuration} ease`,
      };

    switch (meditationStep) {
      case 0: // Inhale
        transformValue = `scale(${1 + currentType.inhaleTime / 4000})`;
        transitionDuration = `${currentType.inhaleTime / 1000}s`;
        break;
      case 1: // Hold In
        transformValue = `scale(${1 + currentType.inhaleTime / 4000})`;
        transitionDuration = `${currentType.holdInTime / 1000}s`;
        break;
      case 2: // Exhale
        transformValue = `scale(1)`;
        transitionDuration = `${currentType.exhaleTime / 1000}s`;
        break;
      case 3: // Hold Out
        transformValue = `scale(1)`;
        transitionDuration = `${currentType.holdOutTime / 1000}s`;
        break;
      default:
        break;
    }

    return {
      transform: transformValue,
      transformOrigin: "center center",
      transition: `transform ${transitionDuration} ease`,
    };
  };

  const nextStep = (prevStep, cycle) => {
    const currentType = meditationTypes.find(
      (type) => type.name === meditationType.name
    );
    let newStep = (prevStep + 1) % cycle.length;
    while (getDuration(currentType, newStep) === 0 && newStep !== prevStep) {
      newStep = (newStep + 1) % cycle.length;
    }
    return newStep;
  };

  const startMeditationCycle = () => {
    const currentType = meditationTypes.find(
      (type) => type.name === meditationType.name
    );

    let duration = getDuration(currentType, meditationStep);

    const timeoutId = setTimeout(() => {
      setMeditationStep((prevStep) => {
        const newStep = nextStep(prevStep, cycleSteps);
        let lastStep;

        if (currentType.holdOutTime > 0) {
          lastStep = getMessage("holdOut");
        } else if (currentType.exhaleTime > 0) {
          lastStep = getMessage("exhale");
        } else if (currentType.holdInTime > 0) {
          lastStep = getMessage("holdIn");
        } else {
          lastStep = getMessage("inhale");
        }

        if (cycleSteps[prevStep] === lastStep) {
          setZenProgress((prevZenProgress) =>
            Math.min(prevZenProgress + 5, 100)
          );
          setLastUpdated(Date.now());
        }

        return newStep;
      });
    }, duration);

    setIntervalId(timeoutId);
  };

  useEffect(() => {
    if (meditationStep !== null && isMeditating) {
      startMeditationCycle();
    }

    return () => {
      if (intervalId) {
        clearTimeout(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meditationStep, isMeditating]);

  useEffect(() => {
    if (thumbsPressed.left && thumbsPressed.right && meditationStep === null) {
      setMeditationStep(0);
      setIsMeditating(true);
    } else {
      if (intervalId) {
        clearTimeout(intervalId);
        setIntervalId(null);
      }
      setMeditationStep(null);
      setIsMeditating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbsPressed.left, thumbsPressed.right]);

  return (
    <Container style={{ overflow: "hidden" }}>
      {!isReturningVisitor && (
        <Dialog
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
            handleSetReturningVisitor();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Box marginBottom={2} fontWeight="bold">
              {getMessage("stepGuide")}:
            </Box>
            <List>
              <ListItem disableGutters>
                <Box
                  pr={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography style={{ fontWeight: 900 }}>1</Typography>
                </Box>
                <ListItemText
                  primary={getMessage("thumbInstruction")}
                  style={{ paddingLeft: 0 }}
                />
              </ListItem>
              <ListItem disableGutters>
                <Box
                  pr={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography style={{ fontWeight: 900 }}>2</Typography>
                </Box>
                <ListItemText
                  primary={getMessage("breathingGuide")}
                  style={{ paddingLeft: 0 }}
                />
              </ListItem>
              <ListItem disableGutters>
                <Box
                  pr={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography style={{ fontWeight: 900 }}>3</Typography>
                </Box>
                <ListItemText
                  primary={getMessage("zenBarInfo")}
                  style={{ paddingLeft: 0 }}
                />
              </ListItem>
            </List>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setIsDialogOpen(false);
                handleSetReturningVisitor();
              }}
            >
              {getMessage("cool")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isTouchDevice() ? (
        <Modern
          primary={theme.palette.primary.main}
          accent={theme.palette.secondary.main}
        />
      ) : null}
      <ZenProgressBar zenProgress={zenProgress} />
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100vw"
        height="100vh"
        zIndex={-1}
      />
      <Settings
        getMessage={getMessage}
        themes={themes}
        color={theme.palette.primary.main}
        setSelectedTheme={setSelectedTheme}
      />
      {!isTouchDevice() && meditationStep !== null ? (
        <Button
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: 75,
          }}
          onClick={(e) => {
            if (intervalId) {
              clearInterval(intervalId);
              setIntervalId(null);
            }
            setMeditationStep(null);
            setThumbsPressed(() => ({ right: false, left: false }));
          }}
        >
          Cancel
        </Button>
      ) : null}
      <Select
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }}
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: 120,
        }}
        value={meditationType.name}
        onChange={(e) => {
          if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
          }
          const newMeditationType = meditationTypes.find(
            (type) => type.name === e.target.value
          );
          setMeditationType(newMeditationType);
          localStorage.setItem(
            "chillaxly-meditation-type",
            JSON.stringify(newMeditationType)
          );
          setNotification(generateMeditationMessage(newMeditationType));
          setMeditationStep(null);
          setThumbsPressed(() => ({ right: false, left: false }));
        }}
      >
        {meditationTypes.map((type) => (
          <MenuItem key={type.name} value={type.name}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              {getMessage(type.key)}
            </Box>
          </MenuItem>
        ))}
      </Select>

      <Box textAlign="center" paddingTop={4} paddingBottom={16}>
        <Typography
          fontWeight={700}
          variant="h4"
          gutterBottom
          color={theme.palette.secondary.main}
          style={getTransitionStyle()}
        >
          {!isTouchDevice() && meditationStep === null ? (
            <Button
              onClick={() =>
                setThumbsPressed(() => ({ right: true, left: true }))
              }
            >
              {getMessage("startPrompt")}
            </Button>
          ) : null}

          {isTouchDevice() && meditationStep === null
            ? getMessage("beginMeditation")
            : cycleSteps[meditationStep]}
        </Typography>

        {/* Left Thumb */}
        {isTouchDevice() ? (
          <Box
            {...bindLeftThumb()}
            style={{
              position: "absolute",
              bottom: 2,
              left: 2,
              transform: thumbsPressed.left ? "scale(0.95)" : "none",
              transition: ".5s",
              color: thumbsPressed.left
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
            }}
          >
            <Fingerprint
              style={{ fontSize: "6rem", transform: "scaleX(-1)" }}
            />
          </Box>
        ) : null}
        {/* Right Thumb */}
        {isTouchDevice() ? (
          <Box
            {...bindRightThumb()}
            style={{
              position: "absolute",
              bottom: 2,
              right: 2,
              transform: thumbsPressed.right ? "scale(0.95)" : "none",
              transition: ".5s",
              color: thumbsPressed.right
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
            }}
          >
            <Fingerprint style={{ fontSize: "6rem" }} />
          </Box>
        ) : null}
      </Box>
      <Snackbar
        open={notification !== ""}
        autoHideDuration={3000}
        onClose={() => setNotification("")}
        message={notification}
        action={
          <IconButton
            onClick={() => setNotification("")}
            size="small"
            aria-label="close"
            color="inherit"
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
}
