import { useMemo } from "react";

function Raindrop({ left, duration, delay, size }) {
  const style = {
    left: `${left}%`,
    height: `${size}px`,
    width: `${size * 0.2}px`,
    animationDuration: `${duration}s`,
    animationDelay: `${delay}s`,
    opacity: Math.random() * 0.5 + 0.5,
  };

  return <div className="raindrop" style={style}></div>;
}

function Rain() {
  const NUM_DROPS = 75;

  const drops = useMemo(() => {
    const dropElements = [];
    for (let i = 0; i < NUM_DROPS; i++) {
      dropElements.push(
        <Raindrop
          key={i}
          left={Math.random() * 100}
          duration={Math.random() * 1 + 0.5}
          delay={Math.random() * 2}
          size={Math.random() * 6 + 4}
        />
      );
    }
    return dropElements;
  }, []);

  return (
    <div
      style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
      className="rain"
    >
      {drops}
    </div>
  );
}

export default Rain;
